<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"  @hide="closeForm" position="full" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'" > 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"><span v-if="recordId && recordId > 0">Update</span><span v-else>Add</span> {{module_name}}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2">
                                        <label>Class</label>
                                        <Dropdown v-model="form.class_id" optionValue="id" :options="selectedClass" optionLabel="name" placeholder="Select a Class" class="full-width" />
                                        <HasError class="p-error" :form="form" field="class_id" />
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Name</label>
                                        <InputText v-model="form.name" id="name" type="text" placeholder="Name"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="name" /> 
                                    </div>
                                </div>
                                
                                
                            </div>  
                            

                        </div> 
                        
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <ConfirmDialog group="dialog" />
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import Dropdown from 'primevue/dropdown';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    name: '', 
                    class_id:'',
                }),   
              
                module_name:'Registration Form',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
           
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId; 
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            this.getAllClasses();
		},
		methods: { 
            getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                    // this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },  
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                this.form.id = this.recId;
                var url = Vthis.$baseurl+'api/save-nums-registration';
                if(this.recId > 0){ url = Vthis.$baseurl+'api/update-nums-registration'; }
                await this.form.post(url)
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            
           
            
		},
        watch : {
             
        }
}
</script>
 
