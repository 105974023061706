<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3>{{ module_name }}</h3>
                    </template>
                    <template v-slot:end>
                        <Button
                            v-tooltip.top="'Refresh'"
                            :label="showBtnLabels ? 'Refresh' : ''"
                            icon="pi pi-refresh"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-success mr-2"
                            @click="getRecords"
                        />
                        <Button
                            @click="addNewRecord"
                            v-tooltip.top="'Add New'"
                            type="button"
                            icon="pi pi-plus"
                            :label="showBtnLabels ? 'Add New' : ''"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2"
                        />
                    </template>
                </Toolbar>

                <Toolbar class="pl-0 pr-0"> </Toolbar>
                <DataTable
                    :resizableColumns="true"
                    columnResizeMode="fit"
                    ref="dt"
                    :value="records"
                    :paginator="true"
                    sortMode="multiple"
                    class="p-datatable-gridlines p-datatable-sm"
                    :rows="10"
                    dataKey="id"
                    :rowHover="true"
                    :loading="loading"
                    :filters="filters"
                    responsiveLayout="scroll"
                    :globalFilterFields="['name', 'description']"
                    :rowsPerPageOptions="[10, 20, 50, 100]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :scrollable="true"
                    scrollHeight="400px">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search"></i>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%" />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        <div class="table-message">
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" />
                        </div>
                    </template>
                    <template #loading> Loading data. Please wait. </template>
                    <Column
                        v-for="(col, index) of selectedColumns"
                        :field="col.field"
                        :header="col.header"
                        :exportable="col.field == 'action' ? false : true"
                        :sortable="col.field == 'action' ? false : true"
                        :key="col.field + '_' + index"
                        :class="col.field == 'action' ? 'btnAction' : ''"
                        :style="col.field !== 'action' ? 'min-width:20rem;flex-grow:1; flex-basis:160px;' : 'max-width:5rem;flex-grow:1; flex-basis:160px;'"
                    >
                        <template #body="{ data }">
                            <span
                                v-if="col.field == 'name'"
                                @click="
                                    selectedRecordId = data.id;
                                    selectedData = JSON.stringify(data);
                                "
                            >
                                <b>{{ data.name }} </b>
                            </span>

                            <span v-if="col.field == 'class_id'">
                                <b>{{ data.class_name }} </b>
                            </span>
                            <span v-if="col.field == 'date_added'">
                                <b>{{ data.date_added }} </b>
                            </span>
                            
                            <span v-if="col.field == 'action'">
                                <div :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :selectedData="JSON.stringify(data)" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions"></Menu>
                                </div>
                            </span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <Toast />
        <RegistrationForm v-on:formClose="closeForm" v-if="showForm" :selectedData="selectedData" :recordId="selectedRecordId" :showForm="true" />
        <ConfirmDialog group="dialog" />
    </div>
</template>
<script>
import RegistrationForm from './Modals/RegistrationForm.vue';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Registration NUMS',
            actions: [],
            showHelp: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            showView: false,
            selectedRecordId: 0,
            saveAndClose: false,
            selectedData: '',
        };
    },
    components: {
        RegistrationForm,
    },
    created() {
        this.actions.push({
            label: 'Edit',
            icon: 'pi pi-refresh',
            command: () => {
                this.getClickedRecord('update');
            },
        });

        this.actions.push({
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
                this.getClickedRecord('delete');
            },
        });

        this.initFilters();
        this.columns = [
            { field: 'action', header: 'Action' },
            { field: 'name', header: 'Name' },
            { field: 'class_id', header: 'Class' },
            { field: 'date_added', header: 'Date Added' },
        ];
        this.selectedColumns = this.columns;
    },
    computed: {},
    mounted() {
        this.getRecords();
    },
    methods: {
        addNewRecord() {
            this.selectedData = null;
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        getClickedRecord(action) {
            if (action == 'view') {
                this.showView = true;
            } else if (action == 'update') {
                this.showForm = true;
            }
            else if (action == 'delete') {
                
                this.deleteRecord(this.selectedRecordId);
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.selectedData = event.currentTarget.getAttribute('selectedData');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        closeForm() {
            // if(!this.selectedRecordId){
            this.getRecords();
            // }
            this.showForm = false;
            this.showView = false;
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.axios
                .post('api/get-admission-application-nums')
                .then((response) => {
                    this.records = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },

        onToggle(value) {
            this.selectedColumns = this.columns.filter((col) => value.includes(col));
        },
        deleteRecord(recId){ 
             
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        Vthis.loading = true; 
                        Vthis.axios.post('api/delete-initiation',{id:recId})
                        .then( () => {   
                            Vthis.showMessage('Deleted successfully','success'); 
                        }).catch((error) => {   
                            let message = Vthis.customError(error.response ); 
                            Vthis.showMessage(message,'error'); 
                        }).finally(() => { 
                            Vthis.loading = false;
                            this.getRecords();
                            //this.emitter.emit('Reload',true);
                           

                        }) 
                        
                    }, reject: () => {
                       //  this.loading = false;
                    }
                }); 
                
        }, 
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        isRTL() {
            return this.$appState.RTL;
        },
    },
    watch: {},
};
</script>
